body{
    background-color: #0d0331;
    overflow-x: hidden;
}
.nav-link{
    font-weight: 600 !important;
    font-size: 16px;
}
a{
    text-decoration: none;
}
.icon-heading{
    font-size: 40px;
    /* position: absolute; */
    font-weight: 800;
    margin-top: -12px;
    margin-left: -15px;
    color: #00c483;
}
.card-home-feature-content{
    height: 120px;
}
.icon-leading{
    font-size: 40px;
    /* position: absolute; */
    font-weight: 800;
    margin-left: -10px;
    margin-top: -10px;
    color: #00c483;
}
.nav-link:hover{
    color: #00c483 !important;
}
.custom-scale{
    padding: 10px 40px;
    border-radius: 6px;
    color: white;
    font-weight: 700;
    border: 2px solid #00c483 !important;
    background-color: #00c483 ;
    transition: 600ms linear;
    /* box-shadow: 0px 0px 10px #d2e3dd, 
                2px 2px 15px #4d826f8b,
                2px 2px 20px #2a9871a1; */

}
.custom-scale:hover{
    color: white;
}
.c-white{
    color: white;
}
.c-white-600{
    color: rgba(255, 255, 255, 0.6);
}
.bg-contact{
    background-color: #8a5de12b;
    border-radius: 5px;
}
.border-r-w{
    border-radius: 20px;
    /* margin-left: -10px; */
    position: relative;
    margin: auto;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-0.3988deg) skew(0deg, 0deg); transform-style: preserve-3d; will-change: transform;
    /* overflow: hidden; */
}
.border-r-w .img-pos-3{
    /* position: absolute; */
    margin: auto;
    margin-top: 20px;
    /* margin-left: -80px; */
    border-radius: 10px;
    padding-left: 2px;
    width: 100%;
    /* border: 1px solid gray; */
    /* border-left: 14px solid #00c483; */
    /* border-right: 14px solid #00c483; */
    /* box-shadow: 0px 10px 100px rgba(0, 194, 129, 0.4); */
    /* box-shadow: 0px 10px 100px rgba(99, 67, 200, .7); */
    top: 0px !important;
    opacity: 1;
    scale: 1.1;
    z-index: 20;
    /* scale: 1.4; */
}
.border-r-w .img-pos-3ab{
    position: absolute;
    top: 110px;
    z-index: 10;
    left: -80px;
}
.border-r-w .img-pos-nab{
    position: absolute;
    top: -300px;
    width: 200px !important;
    right: -170px;
    opacity: .7;
    backdrop-filter: .4;
}
.icon-title{
    color: #6343c8;
}
.mt-20{
    margin-top: 100px;
}
.circ1{
    padding: 10px 110px;
    border-radius: 50px;
    background-color: #6343c8;
    margin: 10px;
    box-shadow: 2px 2px 25px #6343c8;
}
.circ2{
    padding: 10px 20px;
    border-radius: 50px;
    background-color: #00c483;
    margin: 10px;
    box-shadow: 2px 2px 20px #00c483;

}
.circ3{
    padding: 10px 110px;
    border-radius: 50px;
    background-color: #cf5e5e;
    box-shadow: 2px 2px 20px #cf5e5e;
    margin: 60px;
}
.circ4{
    padding: 10px 110px;
    border-radius: 50px;
    background-color: #2c2c2c66;
    box-shadow: 2px 2px 20px #9c9c9c66;
    margin: 120px;
}
.home-feature-l,
.card.home-feature{
    padding: 40px 32px 56px;
    text-align: left;
    transition: 300ms linear;
    cursor: pointer;
}
.home-feature-l{
    background-color: #302c3f !important;
}
.image.card-home-feature{
    width: 60px;
    margin-bottom: 30px;
}
.card-home-feature{
    align-items: flex-start;
    text-align: left;
}
.card-home-feature-content{
    width: 100%;
}
.card-home-feature-try{
    width: 54%;
    margin: auto;
    text-align: center;
}
.card{
    overflow: hidden;
    border-radius: 22px;
    background: linear-gradient(30deg, #362087, #130641);
    box-shadow: 0 5px 14px 0 rgb(0 0 0 / 10%);
}
.fogg-css-shadow{
    position: absolute;
    /* z-index: -1; */
    z-index: 0; /*make this value negative if doesnt work*/
    box-shadow: 0 -130px 200px 80px #dbe1de;
    /* width: 0; */
    top: 360px;
    right: 160px;
    /* box-shadow: 2px 2px 20px rebeccapurple; */
}
.button-secondary {
    margin-top: 25px;
    /* padding: 22px 56px; */
    border-radius: 1000px;
    color: white;
    padding-left: 12px;
    border: none;
    height: 50px;
    text-decoration: none;
    background-color: #0d052e36;
}
.home-feature:hover{
    transform: translateY(-10px);
    box-shadow: 1px 10px 25px rgba(0, 196, 131, 0.3);
    border: 1px solid rgba(0, 196, 131, 0.4);
}
.home-feature-l:hover{
    transform: translateY(-10px);
    box-shadow: 1px 10px 25px rgba(0, 196, 131, 0.3);
    border: 1px solid rgba(0, 196, 131, 0.4);
    /* background: ; */
}
.vdoframe{
    border-radius: 20px;
    border: 1px solid #35443f;
    padding: 2px !important;
}
.ctx-p{
    padding: 20px !important;
}
.link-dark:hover svg{
   color: #00c483 !important;
   cursor: pointer;
   scale: 1.1;
}
.grid-style-recat2{
    height: 60px;
    margin-top: 60px;
    position: absolute;
    right: -90px;
    top: -100px;
    display: flex;
}
.get-demo-card{
    border: 1px solid #302c3f;
    background: #ded7f52b;
    padding-top: 120px;
    padding-bottom: 120px;
    /* border-radius: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
}
.get-demo-card h2{
    font-size: 2rem;
    font-weight: 600;
    color: whitesmoke;
}
.main-dashboard-wrapper{
    overflow: hidden;
}
.mg-30{
    margin-left: 8%;
}
.my-form-tf{
    width: 70%;
    z-index: 0;
    /* height: 350px; */
    margin: 20px auto;
    background: #EAEAF7;
    border-radius: 10px;
    padding: 80px;
    position: relative;overflow: hidden;
}
.justify-al-c{
    justify-content: center;
    display: flex;
}
.justify-al-c .img-abs{
    left: 20px;
    opacity: 0.8;
}
.my-button{
    background: #00c483;
    color: white;
    border-radius: 6px;
    border: none;
    padding: 10px 20px;
    cursor: pointer !important;
    box-shadow: 0 2px 100px #302c3f;

}
.g-color{
    color: #00c483;
}
.footer-fv{
    border-top: 1px solid #00c483;
}
.cust-swt .Mui-checked{
    color: #00c483 !important;
}
.cust-swt{
    display: flex;
    align-items: center;
    grid-gap: 2;
}
.div-label-switch{
   margin: 0px 20px;
   color: white;
   font-weight: 600;
}
.cust-swt .Mui-checked+.MuiSwitch-track{
    background: #00c483 !important;
}
.cust-swt .MuiSwitch-track{
    background: white !important;
}

/* #6343c8 */
.img-abs{
    position: absolute;
    top: -40px;
    opacity: .7;
    z-index: -1;
    transform: rotateZ(-30deg);
}
.pricing{
    width: 26%;
    min-height: 800px;
    background: none;
    padding-left: 20px !important ;
    padding-bottom: 0px !important;
    border: 1px solid #00c483;
}
.home-feature.pricing:hover{
    box-shadow: none;
}
.justify-space-center{
    justify-content: center;
}
.selected{
    background: #00c483;
}
.c-balck{
    color: white;
}
.c-balck-600{
    font-weight: 600;
    color: rgba(255, 255, 255, 0.6);
}
.selected .c-balck{
    color: black !important;
}
.selected .c-balck-600{
    color: rgba(0, 0, 0, .6) !important;
}
.title-p{
    font-size: 3rem;
}
.card-home-feature-pricing-button{
    text-align: center;
    margin: 20px auto;
}
.btn-crt{
    background: black;
    color: honeydew;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
}
.card-home-feature-pricing-bullet{
    padding: 0px;
    margin-top: 20px;
}
.card-home-feature-pricing-bullet ul{
    padding: 0px !important;
    list-style: none;
}
.card-home-feature-pricing-bullet ul li{
    /* padding-top: 8px; */
    /* padding-bottom: 8px; */
    /* margin-left: 10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
}
.custom-pri li{
    /* justify-content: space-between; */
}
.yes-p::before{
    padding: 8px;
    /* position: absolute;
    left: 5px;
    top: -1px; */
    content: url('https://api.iconify.design/material-symbols/check-small-rounded.svg?width=24&height=24');
}
.no-p::before{
    padding: 8px;
    /* left: 0px;
    position: absolute; */
    content: url('https://api.iconify.design/mdi/window-close.svg?width=20&height=20');
}
.n-selected{
    color: white !important;
}
.n-selected .yes-p::before{
    content: url('https://api.iconify.design/material-symbols/check-small-rounded.svg?width=24&height=24&color=white');
}
.n-selected .no-p::before{
    color: white !important;
    content: url('https://api.iconify.design/mdi/window-close.svg?width=20&height=20&color=white');
}
.n-selected .btn-crt{
    background: white;
    color: black;
}
.yes-p input{
    margin: 0px 10px;
    float: right;
    background: #00c483;
    color: #00c483;
}
.check-p input{
    margin-left: 1px;
    margin-right: 12px;
    cursor: pointer;
    scale: 1.2;
}
.justify-space-between{
    justify-content: space-between;
}
.MuiSlider-track,
.MuiSlider-rail,
.MuiSlider-thumb{
    color: #00c483 !important;
}

.check-p{
    padding: 8px;
}
.about-hero-content{
    width: 80%;
    color: white;
}
.text-color-accent{
    padding: 1px;
    font-weight: 600;
    color: #6343c8;
    margin-left: 10px;
    text-decoration: underline;
}
.paragraph-large{
    font-size: 24px;
    margin: 30px auto;
}
.about-hero-h2{
    margin-top: 20px;
    font-size: 40px;
    color: white;
}
.about-hero-p{
    color: whitesmoke;
    padding-top: 10px;
    line-height: 30px;
    font-size: 22px;
}
.img-rotate{
    transform: rotateZ(-10deg);
    margin: 100px auto;
    width: 400px; 
    height: 400px
}
.jsc{
    justify-content: space-between;
    width: 100%;
}
.card.home-feature.pricing{
    width: 100%;
    padding-bottom: 20px;
    height: unset;
}
.nav{
    justify-content: center;
}
.fogg_img{
    width: 50%;
    overflow: hidden;
}
header{
     justify-content: center;
}
.container_navbarwrap{
    width: 90%;
    margin: auto;
    position: relative;
    z-index: 1;
    justify-content: space-evenly !important;
}
.flex-sb{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.justify-content-sm-between{

}
.aboutus-section-left{
    width: 60%
}
.contact-pricing-section{
    border: 1px solid #00c483e9;
    /* border-image: linear-gradient(to left, #00c483, #6343c8 ) 1% 10%; */
    border-image-outset: 10px;
    text-align: left;
    border-radius: 20px;
    border-image-width: 1px;
}
.contact-pricing-section-p{
    font-size: 24px;
    color: #00c483;
    font-weight: 500;
}
.contact-pricing-section-span{
    font-size: 18px;
    color: grey;
    padding: 10px auto;
    font-weight: 500;
}
.contact-pricing-section-button{
    text-transform: none !important;
    border: 1px solid #00c483e9 !important;
    color: #00c483e9 !important;
    border-radius: 20px !important;
}
.list-styles-none{
    list-style: none;
    padding: 0px !important;
    color: #d7e0dd;
    font-size: 16px;
}
.list-styles-none li{
    margin: 9px auto;
}
.icon-close-drawer{
   font-size: 36;
   cursor: pointer; 
   border: 1px solid #008eab;
   background: rgb(197, 199, 255);
   border-radius: 20px;
   padding: 3px;
}
.ul-custom-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    
}
.input-label{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}
.input-label label{
    font-size: 18px;
}
.input-label select,
.input-label textarea,
.input-label input{
    font-size: 16px;
    background-color: #e7e0ff;
    outline: none;
    border: 1px solid #00c483;
    border-radius: 4px;
    width: 90%;
    height: 40px;
    padding-left: 10px;
}
.MuiInputBase-root.MuiOutlinedInput-root{
    font-size: 16px;
    background-color: #e7e0ff;
    outline: none;
    border: 1px solid #00c483;
    border-radius: 4px;
    width: 90%;
    outline: none;
    height: 40px;
}
.span-count{
    font-size: 12px;
    color: grey;
    float: right;
}
.MuiInputBase-root.MuiOutlinedInput-root:focus{
    border: none;
    outline: none;
}
.input-label textarea{
    height: 100px;
}
.form-custm-css{
    width: 100%;
}
.form-custm-css span{
    font-family:Arial, Helvetica, sans-serif;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight{
  /* border-top-left-radius: 20px; */
  /* border-bottom-left-radius: 20px; */
  background-color: transparent;
}

.background-box-drawer{
    /* background: rgba(200, 233, 255, 0.948); */
    background: rgb(221, 222, 255);
}
.yes-p-c::before{
    padding: 8px;
    /* position: absolute; */
    left: 5px;
    top: -1px;
    content: url('https://api.iconify.design/bi/check2-square.svg?color=rgba(0%2C%20196%2C%20131%2C%200.9137254901960784)&width=16&height=16');
}
@media screen and (max-width: 1200px) {
    .navbarwrap{
        width: 100% !important;
    }
    .img-pos-3{
        zoom: 1 !important;
    }
    .img-rotate{
        width: 700px;
        height: 400px;
        margin: 20px auto; 
    }
    .aboutus-section-left{
        width: 90%
    }
}

@media screen and (max-width: 1024px) {
    .home-feature-l, .card.home-feature{
        height: 400px;
    }
    .errorpage-main{
        font-size: 260px !important;
        margin-top: -100px !important;
    }
    .jsc{
        justify-content: space-between;
    }
    .card.home-feature.pricing{
        width: 30%;
        padding-bottom: 20px;
        height: unset;
    }
    .container{
        width: 90% !important;
        min-width: 90% !important;
    }
    .container-default{
        min-width: 90% !important;
        width: 90% !important;
        margin-right: 5% !important;
        margin-left: 5% !important;
    }
    .home-feature-m{
        flex-wrap: wrap !important;
        justify-content: center;
    }
    .home-feature-m .card{
       width: 45%;
    }
    .get-demo-card{
       justify-content: space-between !important;
       grid-gap: 10px;
    }
  
    .footer-m-info{
        width: 100% !important;
    }
    video{
        width: 90% !important;
    }
    header{
        display: block !important;
        justify-content: flex-start !important;
    }
    .header-list-menu{
        width: 100%;
        margin-left: -10px;
        justify-content: flex-start !important;
    }
    .nav{
        margin-left: 20px;
    }
    .text-end{
        width: auto;
        margin-left: 20px;
    }
    .border-r-w .img-pos-3{
        position: relative;
        zoom: 1;
        width: 90%;
        margin: 5%;
    }
}
.flex-sm-row.foot-form {
   flex-wrap:wrap ;
}
@media screen and (max-width: 768px){
    .ctx-p{
        padding: 50px !important;
    }
    .card-home-feature-try{
        width: 78%;
    }
    .fogg_img{
        display: none;
    }
    .get-demo-card{
        display: block;
    }
    .get-demo-card h2{
        font-size: 24px;
    }
    .errorpage{
        zoom: .8;
    }
    .img-pos-3{
      display: none;
    }
    .img-pos-3ab{
        /* position: relative !important;
        top: -30px !important;
       margin: auto !important  ; */
       display: none;
    }

    .border-r-w{
        display: flex;
    }
    video{
        width: 450px !important;
    }
    .nav{
        justify-content: flex-start !important;
    }
    .jsc{
        justify-content: center;
    }
    .card.home-feature.pricing{
        width: 80%;
        margin: auto;
        padding-bottom: 20px;
        height: unset;
    }
    .whatwe-do-m{
      display: none;
    }
    .sec-fot-m{
        width: 80%;
        margin: auto;
    }
    .my-form-tf{
        width: 100%;
        padding: 80px 10px;
    }
    .my-button{
        font-size: 14px !important;
    }
    .card.home-feature{
       width: 80%;
       margin: auto;
       height: auto;
       min-height: 200px;
    }
    .card.home-feature-l{
        width: 80%;
        margin: auto;
        height: auto;
        padding-bottom: 20px;
     }
     .card-home-feature-content{
       height: auto;
     }
     .contact-pricing-section{
        flex-direction: column;
        text-align: center;

     }
     .contact-pricing-section .contact-pricing-section-right,
     .contact-pricing-section .contact-pricing-section-left{
        width: 100% !important;
        border-right: none !important;
     }
     .contact-pricing-section-left{
        border-bottom: 1px solid rgb(0, 196, 131);
     }
}
@media screen and (max-width: 425px){
    .errorpage{
        zoom: .5;
    }
    .img-rotate{
       width: 400px;
       height: 380px;
    }
    .card.home-feature,
    .card.home-feature-l{
      width: 100%;
      margin: auto;
    }
    .card.home-feature.pricing{
        width: 100% !important;
    }
    .img-contact{
        /* margin-left: -40px ; */
        width: 90%;
    }
    .icon-heading{
     font-size: 30px;
    }
    .nav-link{
        font-size: 14px;
    }
}