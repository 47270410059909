@import url("https://fonts.googleapis.com/css2?family=Fontdiner+Swanky&family=Roboto:wght@500&display=swap");


aside {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/right-edges.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 25px 100%;
}

aside img {
    display: block;
    height: auto;
    width: 400px;
}

.errorpage{
    width: 90%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.errorpage-main {
  margin-top: -260px;
  text-align: left;
}
.errorpage h1{
    color: #e6d9ad8d;
    font-size: 560px;
    opacity: .2;
    z-index: -1;
    shape-rendering: optimizeSpeed;
    pointer-events: none;
    text-shadow: 2px 2px 200px #787998f7;
}
.errorpage h2{
    color: #00c483;
    font-size: 40px;
    /* opacity: .2; */
    z-index: -1;
    shape-rendering: optimizeSpeed;
    pointer-events: none;
    text-shadow: 2px 2px 200px #787998f7;
}
.errorpage p{
    color: #a5d1c3;
    opacity: .5;
    font-size: 20px;
    text-align: center;
}
.errorpage-button{
    color: #00c483;
    border: none !important;
    text-transform: none !important;
    /* padding: 20px !important; */
    /* color: white; */
    font-weight: bold;
    font-size: 20px !important;
    border-radius: 10px;
}